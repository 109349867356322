import './OfficesTemplate.scss'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { FC } from 'react'

import BioCatch from '../../components/Biocatch/Biocatch'
import { BolUserProvider } from '../../components/BolUser/BolUserProvider'
import BreadCrumb from '../../components/Layout/Header/BreadCrumb/BreadCrumb'
import Layout from '../../components/Layout/Layout'
import OfficesAndCashiers from '../../components/OfficesAndCashiers/OfficesAndCashiers'
import SalesForce from '../../components/SalesForce/SalesForce'
import SEO from '../../components/SEO/SEO'
import * as SanitySchema from '../../lib/types/sanity-schema'
import { Business } from '../../services/business-service'
import { LKSiteSettingsProvider } from '../../services/SiteSettingsProvider'

export const query = graphql`
  query OfficesTemplateQuery($lang: String!, $siteSettingsId: String!) {
    site: sanitySiteSettings(_id: { eq: $siteSettingsId }) {
      defaultSiteTab {
        _id
      }
      officesInfoCard {
        illustration {
          image {
            ...LKImageFragment
          }
        }
        text: _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
    locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export interface OfficesTemplateProps {
  data: {
    site: SanitySchema.SiteSettings
  }
  pageContext: {
    offices: Business[]
    i18n: { language: string }
    alternativeUrls: {
      [key: string]: string
    }
    currentUrl: string
    siteSettingsId: string
  }
}

const OfficesTemplate: FC<OfficesTemplateProps> = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const { offices, currentUrl, alternativeUrls, i18n, siteSettingsId } = pageContext
  const infoCard = data?.site?.officesInfoCard
  const language = i18n.language || 'es'

  return (
    <LKSiteSettingsProvider siteSettingsId={siteSettingsId}>
      <BolUserProvider>
        <Layout
          language={language}
          alternativeUrls={alternativeUrls}
          currentTabId={data?.site?.defaultSiteTab?._id}
          isHome={false}
        >
          <SEO
            title={t('offices.title')}
            description={t('offices.description')}
            keywords={t('offices.keywords')}
            lang={language}
            slug={currentUrl}
            alternativeUrls={Object.entries(alternativeUrls).map(([lang, href]) => ({
              lang,
              href,
            }))}
            breadcrumbsSchema
          />
          <BreadCrumb url={currentUrl?.replace(/^\/|\/$/g, '')} />

          <OfficesAndCashiers offices={offices} infoCard={infoCard} />

          <SalesForce />
          <BioCatch />
        </Layout>
      </BolUserProvider>
    </LKSiteSettingsProvider>
  )
}

export default OfficesTemplate
